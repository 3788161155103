

























import { Component } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import { reduce } from 'lodash'

// components
import TestQuestionPassingView from '@/components/views/exercise/TestQuestionPassingView.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import MasterDashboardModule from '@/store/modules/master/dashboard'
import {
  EducationLargeTaskDecideQuestionAnswerRequest,
  EducationLargeTaskDecideQuestionRequest,
  EducationLargeTaskDecideRepetitionQuestionRequest,
  EducationLargeTaskTestQuestionResource,
  TaskQuestionType,
} from '@/store/types'

@Component({
  components: {
    TestQuestionPassingView,
    ValidationObserver,
  },
})
export default class MasterTestRepetition extends NotifyMixin {
  private get masterGroupID() {
    return +this.$route.params.masterGroupID
  }

  private get test() {
    return MasterDashboardModule.repetitionTest
  }

  // Вопросы на соответствие
  private get rationQuestions() {
    const ratio: { [key: number]: number } = {}
    if (this.test) {
      this.test.questions.filter(question => question.type.value === TaskQuestionType.RATIO).forEach(question => {
        ratio[question.id] = question.answers.length
      })
    }
    return ratio
  }

  private get answeredQuestions () {
    return reduce(this.form.questions, (sum: number, question: EducationLargeTaskDecideQuestionRequest) => {
      if (question.answers.length) {
        const answersCount = reduce(question.answers, (answerSum: number, answer: EducationLargeTaskDecideQuestionAnswerRequest) => {
          if (answer.value || answer.sequenceId) {
            answerSum += 1
          }
          return answerSum
        }, this.questionsNoAnswerTypeCount)
        if (answersCount - this.questionsNoAnswerTypeCount) {
          if (!(this.rationQuestions[question.id] && this.rationQuestions[question.id] !== answersCount - this.questionsNoAnswerTypeCount)) {
            sum += 1
          }
        }
      }
      return sum
    }, this.questionsNoAnswerTypeCount)
  }

  // Кол-во вопросов с типом "Без ответа"
  private get questionsNoAnswerTypeCount() {
    if (this.test) {
      return this.test.questions.filter(question => question.type.value === TaskQuestionType.NO_ANSWER).length
    }
    return 0
  }

  private get isLargeView() {
    return !(this.$vuetify.breakpoint.name === 'xs')
  }

  private form: EducationLargeTaskDecideRepetitionQuestionRequest = {
    questions: [],
  }

  private mounted() {
    if (!this.test && this.masterGroupID) {
      MasterDashboardModule.fetchRepetitionTest(this.masterGroupID)
        .then(response => {
          if (!response.questions.length) {
            this.$emit('noErrors')
            this.$router.replace({ name: 'master' })
            this.notifyInfo('У тебя нет ошибок.')
          } else {
            this.fillForm(response.questions)
          }
        })
        .catch(() => {
          this.notifyError('Что-то пошло не так. Повторите попытку позднее или перезагрузите страницу')
          this.$router.replace({
            name: 'master',
          })
        })
    } else if (this.test) {
      this.fillForm(this.test.questions)
    }
    this.$bus.$on('deleteAnswer', this.deleteAnswer)
  }

  private destroyed() {
    this.$bus.$off('deleteAnswer', this.deleteAnswer as any)
  }

  private deleteAnswer(payload: { answerID: number, questionIndex: number }) {
    this.form.questions[payload.questionIndex].answers = this.form.questions[payload.questionIndex].answers.filter(answer => answer.value !== payload.answerID)
  }

  private fillForm(test: EducationLargeTaskTestQuestionResource[]) {
    this.form.questions = test.map((question: EducationLargeTaskTestQuestionResource) => ({
      answers: question.userAnswers,
      id: question.id,
    }))
  }

  private handleSend() {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          MasterDashboardModule.sendRepetitionTest({
            body: this.form,
            masterGroupId: this.masterGroupID,
          })
            .then(response => {
              this.$emit('sendRepetitionTest', response)
            })
            .catch(this.notifyError)
        } else {
          const errors: string[] = []
          Object.keys(form.errors).forEach(key => {
            if (form.errors[key].length) {
              errors.push(key)
            }
          })
          const questions: string[] = Array.from(new Set(errors.map(value => value.split('_')[1])))
          this.notifyError(`${questions.length ? `Неотвеченные вопросы: ${questions.map(q => `№${q}`).join(', ')}` : 'Ты не дал(-а) ответы на все вопросы'}`)
        }
      })

  }
}
