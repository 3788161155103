import { render, staticRenderFns } from "./QuestionMultipleForm.vue?vue&type=template&id=4cc4928a&scoped=true&lang=pug&"
import script from "./QuestionMultipleForm.vue?vue&type=script&lang=ts&"
export * from "./QuestionMultipleForm.vue?vue&type=script&lang=ts&"
import style0 from "./QuestionMultipleForm.vue?vue&type=style&index=0&id=4cc4928a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cc4928a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
installComponents(component, {VCheckbox})
