



















import { Component, Prop, Vue } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

import { EducationLargeTaskDecideQuestionRequest, EducationLargeTaskTestQuestionResource } from '@/store/types'

@Component({
  components: {
    ValidationProvider,
  },
})
export default class QuestionMultipleForm extends Vue {
  @Prop({ required: true })
  private question!: EducationLargeTaskTestQuestionResource

  @Prop({ required: true })
  private questionIndex!: number

  @Prop({ required: true })
  private questionForm!: EducationLargeTaskDecideQuestionRequest

  @Prop({ default: false })
  private disabled!: boolean

  private innerValue: number[] = []

  private mounted () {
    this.updateInnerValue()
    this.$bus.$on('updateTestFormOnSocket', this.updateInnerValue)
  }

  private destoyed() {
    this.$bus.$off('updateTestFormOnSocket', this.updateInnerValue as any)
  }

  private updateInnerValue() {
    if (this.questionForm.answers.length) {
      this.innerValue = this.questionForm.answers.map((answer) => +(answer.value || 0))
    }
  }

  private handleChange (value: number[]) {
    this.questionForm.answers = value.map((v: number) => ({ value: v }))
  }
}
