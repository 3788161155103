




























import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import { Bind, Debounce } from 'lodash-decorators'
// import * as Sentry from '@sentry/vue'

import QuestionRatioView from '@/components/views/exercise/question/QuestionRatioView.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import {
  EducationLargeTaskDecideQuestionAnswerRequest,
  EducationLargeTaskDecideQuestionRequest,
  EducationLargeTaskQuestionSequenceResource, EducationLargeTaskTestQuestionResource, NameValueResource,
} from '@/store/types'
import { alphabet, alphabetEng } from '@/utils/constants'

@Component({
  components: {
    QuestionRatioView,
    TextInput,
    ValidationProvider,
  },
})
export default class QuestionRatioForm extends Vue {
  @Ref() answerInput!: Vue[]

  @Prop({ required: true })
  private question!: EducationLargeTaskTestQuestionResource

  @Prop({ required: true })
  private questionIndex!: number

  @Prop({ required: true })
  private questionForm!: EducationLargeTaskDecideQuestionRequest

  @Prop({ required: true })
  private subject!: NameValueResource

  @Prop({ default: false })
  private disabled!: boolean

  private innerValue: string[] = []

  private alphabet = this.subject.value === 9 ? alphabetEng : alphabet
  private inputs: Array<{ el: HTMLInputElement, touched: boolean }> = []

  private mounted () {
    this.$nextTick(() => {
      this.inputs = this.answerInput.map(el => ({ el: el.$el.querySelector('input') as HTMLInputElement, touched: (el.$el.querySelector('input') as HTMLInputElement).value !== '' }))
    })
    this.updateInnerValue()
    this.$bus.$on('updateTestFormOnSocket', this.updateInnerValue)
  }

  private destoyed() {
    this.$bus.$off('updateTestFormOnSocket', this.updateInnerValue as any)
  }

  private updateInnerValue() {
    if (this.questionForm.answers.length) {
      this.innerValue = this.question.answers.map((answer) => {
        const findAnswer = this.questionForm.answers.find((userAnswer: EducationLargeTaskDecideQuestionAnswerRequest) => answer.id === userAnswer.value)

        if (!findAnswer) return ''
        else {
          const sequence = this.question.sequences.find((sequence: EducationLargeTaskQuestionSequenceResource) => sequence.id === findAnswer.sequenceId)
          return sequence ? sequence.position.toString() : ''
        }
      })
    }
  }

  private handleInput(sequenceValue: string, answerID: number, index: number) {
    const answerIndex = this.questionForm.answers.findIndex((answer: EducationLargeTaskDecideQuestionAnswerRequest) => answer.value === answerID)
    const answer = Number(sequenceValue.replace(/\D/g, ''))
    /*Sentry.addBreadcrumb({
      category: 'ui.change',
      data: {
        answer,
        answerCharCodeAt: answer.toString().charCodeAt(0),
        answerID,
        questionIndex: this.questionIndex,
        sequenceValue,
        sequenceValueCharCodeAt: sequenceValue.split('').map(char => char.charCodeAt(0)),
      },
      level: 'info',
      message: 'Значение инпута изменено',
    })*/

    if (answer && answer <= this.question.sequences.length) {
      const sequence = this.question.sequences.find((sequence: EducationLargeTaskQuestionSequenceResource) => sequence.position === answer)

      if (sequence) {
        if (this.questionForm.answers[answerIndex]) {
          this.questionForm.answers[answerIndex].sequenceId = sequence.id
        } else {
          this.questionForm.answers.push({ sequenceId: sequence.id, value: answerID })
        }
      }/* else {
        Sentry.addBreadcrumb({
          category: 'message',
          data: {
            answer,
            answerCharCodeAt: answer.toString().charCodeAt(0),
            answerID,
            questionIndex: this.questionIndex,
            sequenceValue,
            sequenceValueCharCodeAt: sequenceValue.split('').map(char => char.charCodeAt(0)),
            sequencesLength: this.question.sequences.length,
          },
          level: 'info',
          message: 'Ответ, данный мастером не был найден в позишенах sequences',
        })
      }*/
    } else {
      /*Sentry.addBreadcrumb({
        category: 'message',
        data: {
          answer,
          answerCharCodeAt: answer.toString().charCodeAt(0),
          answerID,
          questionIndex: this.questionIndex,
          sequenceValue,
          sequenceValueCharCodeAt: sequenceValue.split('').map(char => char.charCodeAt(0)),
          sequencesLength: this.question.sequences.length,
        },
        level: 'info',
        message: 'Ответ на вариант вопроса удалён кодом',
      })*/
      this.$bus.$emit('deleteAnswer', { answerID, questionIndex: this.questionIndex })
    }
    if (sequenceValue.length && index < this.inputs.length - 1 && !this.inputs[index].touched) {
      this.inputs[index].touched = true
      this.next(index)
    }
  }

  // Переключение на следующий инпут после ввода
  @Bind()
  @Debounce(700)
  private next(index: number) {
    this.inputs[index + 1].el.focus()
  }
}
