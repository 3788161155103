



























import { Component, Ref } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'
import { Route } from 'vue-router/types/router'

// components
import Confirmation from '@/components/modals/Confirmation.vue'
import MasterTestRepetition from '@/components/views/dashboard/master/MasterTestRepetition.vue'
import TestRepetitionTry from '@/components/views/dashboard/master/TestRepetitionTry.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import MasterDashboardModule from '@/store/modules/master/dashboard'
import { EducationLargeTaskTestResource } from '@/store/types'

@Component({
  components: {
    Confirmation,
    MasterTestRepetition,
    TestRepetitionTry,
  },
})
export default class MasterRepetitionTest extends NotifyMixin {
  @Ref() confirm!: Confirmation
  @Ref() noErrors!: Confirmation

  private get masterGroupID() {
    return +this.$route.params.masterGroupID
  }

  private result = false
  private questions: EducationLargeTaskTestResource | null = null

  private showResult(questions: EducationLargeTaskTestResource) {
    this.result = true
    this.questions = questions
  }

  private createRepetitionTest() {
    MasterDashboardModule.fetchRepetitionTest(this.masterGroupID)
      .then((response) => {
        if (!response.questions.length) {
          this.showModalNoErrors()
        } else {
          this.result = false
          this.$bus.$emit('scrollToTop')
        }
      })
  }

  private beforeRouteLeave(to: Route, from: Route, next: any) {
    if (!this.result) {
      this.confirm.open(
        'Уже уходишь?',
        'Если тест не отправлен, твои ответы не сохранятся и ты не увидишь результат. Ты точно хочешь покинуть страницу?',
        {
          buttonCancelText: 'Вернуться к тесту',
          buttonConfirmText: 'Покинуть страницу',
          buttonReverse: true,
        },
      )
        .then(() => next())
        .catch(() => next(false))
    } else {
      next()
    }
  }

  private showModalNoErrors() {
    this.noErrors.open(
      '',
      `
                <svg class="no-error__icon" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="64" height="64" rx="32" fill="#DAEFF0"/>
                  <rect x="12" y="12" width="40" height="40" rx="20" fill="#56C0AD"/>
                  <path d="M43.1808 24.0164C43.0259 23.8602 42.8416 23.7362 42.6385 23.6516C42.4354 23.567 42.2175 23.5234 41.9975 23.5234C41.7775 23.5234 41.5597 23.567 41.3566 23.6516C41.1535 23.7362 40.9691 23.8602 40.8142 24.0164L28.3975 36.4498L23.1808 31.2164C23.02 31.061 22.8301 30.9388 22.622 30.8568C22.4139 30.7748 22.1917 30.7346 21.9681 30.7385C21.7444 30.7424 21.5237 30.7902 21.3186 30.8794C21.1135 30.9685 20.9279 31.0972 20.7725 31.2581C20.6171 31.419 20.4949 31.6089 20.4129 31.817C20.3309 32.0251 20.2907 32.2473 20.2946 32.4709C20.2984 32.6945 20.3463 32.9152 20.4355 33.1203C20.5246 33.3255 20.6533 33.511 20.8142 33.6664L27.2142 40.0664C27.3691 40.2227 27.5535 40.3466 27.7566 40.4313C27.9597 40.5159 28.1775 40.5594 28.3975 40.5594C28.6175 40.5594 28.8354 40.5159 29.0385 40.4313C29.2416 40.3466 29.4259 40.2227 29.5808 40.0664L43.1808 26.4664C43.35 26.3104 43.485 26.1209 43.5774 25.9101C43.6697 25.6993 43.7174 25.4716 43.7174 25.2414C43.7174 25.0113 43.6697 24.7836 43.5774 24.5728C43.485 24.3619 43.35 24.1725 43.1808 24.0164Z" fill="white"/>
                </svg>
                <div class="text-h3-1 no-error">Ого, кажется, нам нечего тебе предложить. Ты перерешал все задания с ошибками.</div>
              `,
      {
        buttonCancelText: 'Супер, пойду дальше',
        buttonConfirmVisible: false,
        buttonReverse: true,
        hideDefaultClose: true,
        messageNoMargin: true,
        persistent: true,
        skin: 'secondary',
      },
    )
      .finally(() => {
        this.$router.replace({ name: 'master' })
      })
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Тест на повторение',
    }
  }
}
