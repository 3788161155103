


























import { reduce } from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

import TextInput from '@/components/_uikit/controls/TextInput.vue'
import {
  EducationLargeTaskDecideQuestionAnswerRequest,
  EducationLargeTaskDecideQuestionRequest,
  EducationLargeTaskTestQuestionResource,
} from '@/store/types'

@Component({
  components: {
    TextInput,
    ValidationProvider,
  },
})
export default class QuestionSequenceForm extends Vue {
  @Prop({ required: true })
  private question!: EducationLargeTaskTestQuestionResource

  @Prop({ required: true })
  private questionIndex!: number

  @Prop({ required: true })
  private questionForm!: EducationLargeTaskDecideQuestionRequest

  @Prop({ default: false })
  private disabled!: boolean

  private innerValue = ''

  private mounted () {
    this.updateInnerValue()
    this.$bus.$on('updateTestFormOnSocket', this.updateInnerValue)
  }

  private destoyed() {
    this.$bus.$off('updateTestFormOnSocket', this.updateInnerValue as any)
  }

  private updateInnerValue() {
    if (this.questionForm.answers[0] && this.questionForm.answers[0].value) {
      this.innerValue = reduce(this.questionForm.answers, (str: string, formAnswer: EducationLargeTaskDecideQuestionAnswerRequest) => {
        const answer = this.question.answers.find((answer) => answer.id === formAnswer.value)
        str = answer ? `${str}${answer.position}` : str
        return str.trim()
      }, '')
    }
  }

  private handleInput (value: string) {
    this.questionForm.answers = []
    const sequence = value.split('')

    sequence.forEach((sign: string) => {
      const answer = this.question.answers.find((answer) => answer.position === +sign)

      if (answer) {
        this.questionForm.answers.push({ value: answer.id })
      }
    })
  }
}
