












































import { Component, Mixins, Prop } from 'vue-property-decorator'

// components
import QuestionIndicator from '@/components/_uikit/QuestionIndicator.vue'
import TestQuestionResultView from '@/components/views/exercise/TestQuestionResultView.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import TestQuestionResultMixin from '@/mixins/TestQuestionResultMixin'
// store
import { EducationLargeTaskTestQuestionResource, EducationLargeTaskTestResource, TaskRateType } from '@/store/types'

@Component({
  components: {
    QuestionIndicator,
    TestQuestionResultView,
  },
})
export default class TestRepetitionTry extends Mixins(NotifyMixin, TestQuestionResultMixin) {
  @Prop({ required: true })
  private questions!: EducationLargeTaskTestResource

  private get isLargeView() {
    return !(this.$vuetify.breakpoint.name === 'xs')
  }

  private isQuestionWithoutRightAnswer(question: EducationLargeTaskTestQuestionResource) {
    return question.rateType.value === TaskRateType.NO_RIGHT
  }
}
