


















import { Component, Prop, Vue } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

import { EducationLargeTaskDecideQuestionRequest, EducationLargeTaskTestQuestionResource } from '@/store/types'

@Component({
  components: {
    ValidationProvider,
  },
})
export default class QuestionSingleForm extends Vue {
  @Prop({ required: true })
  private question!: EducationLargeTaskTestQuestionResource

  @Prop({ required: true })
  private questionIndex!: number

  @Prop({ required: true })
  private questionForm!: EducationLargeTaskDecideQuestionRequest

  @Prop({ default: false })
  private disabled!: boolean

  private innerValue: number | null = null

  private mounted () {
    this.updateInnerValue()
    this.$bus.$on('updateTestFormOnSocket', this.updateInnerValue)
  }

  private destoyed() {
    this.$bus.$off('updateTestFormOnSocket', this.updateInnerValue as any)
  }

  private updateInnerValue() {
    if (this.questionForm.answers[0] && this.questionForm.answers[0].value) {
      this.innerValue = +this.questionForm.answers[0].value
    }
  }

  private handleChange (value: number) {
    this.questionForm.answers = [{ value }]
  }
}
