


































import { Component, Prop, Vue } from 'vue-property-decorator'

// component
import Player from '@/components/_uikit/Player.vue'
// store
import SystemModule from '@/store/modules/system'
import { EducationLargeTaskTestQuestionResource } from '@/store/types'

@Component({
  components: {
    Player,
  },
})
export default class RelatedQuestionView extends Vue {
  @Prop({ required: true })
  private relatedQuestion!: EducationLargeTaskTestQuestionResource

  private get audioList() {
    return SystemModule.audioList
  }

  private get relatedAudioFiles() {
    return this.relatedQuestion.media.filter(media => media.type === 'audio')
  }

  private get relatedMediaFiles() {
    return this.relatedQuestion.media.filter(media => media.type !== 'audio')
  }

  private get mediaCount() {
    return this.relatedMediaFiles.length
  }

  // Нечетное ли кол-во медиа
  private get isEvenMediaCount() {
    return Boolean(this.mediaCount % 2)
  }
}
